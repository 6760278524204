import React,{useEffect,useContext} from 'react';
import { NavLink, Link, useLoaderData, } from "react-router-dom";
import ProgressiveImage from './ProgressiveImage/FirstSlider';
import { ThemeContext } from '../context/ThemeContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Virtual, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/virtual';
import 'swiper/css/pagination';


function Home(props) {
  //console.log(props);
  let projectsData = useLoaderData();
  let projectsCine = projectsData.items['cine'];
  let projectsTv = projectsData.items['tv'];
  let projectsDoc = projectsData.items['doc'];
  //console.log(projectsData.items);
  const theme = useContext(ThemeContext);

  useEffect((props) => {
}, [props]);

  return (
<section className={"pt-12 " + props.transition}>

    <div className="px-12 mx-auto max-w-7xl pt-6">
        <div className="w-full mx-auto text-left md:w-11/12 xl:w-9/12 md:text-center">
          <img style={{filter: 'drop-shadow(3px 3px 23px #116466)'}} className="logoHome pl-20 pb-6 mt-1" src="/img/af-films-logo-gradient.svg" alt=""/>


            <p className="px-0 mb-8 text-lg text-white md:text-xl lg:px-24">
             Somos una productora global comprometida con la creación de experiencias cinematográficas inolvidables que trascienden fronteras y culturas.
            </p>
            <p className="px-0 mb-8 text-lg text-white md:text-xl lg:px-24">
              Nuestra visión es ser líderes en la industria del cine, produciendo contenido de alta calidad que inspire, entretenga y emocione a audiencias de todo el mundo. Creemos en el poder del cine para contar historias que resuenen universalmente, abriendo ventanas a nuevas perspectivas y uniendo a las personas a través del arte de la narrativa visual.
            </p>
            <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                <Link to="./cine" className="inline-flex items-center justify-center w-full px-6 py-3 mb-2 text-lg text-white bg-magenta rounded-xl sm:w-auto sm:mb-0">
                    Películas
                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                  </Link>
                <Link to="./contacto" className="inline-flex items-center justify-center w-full px-6 py-3 mb-2 text-lg bg-magenta rounded-xl sm:w-auto sm:mb-0">
                    Contacto
                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                  </Link>
            </div>
        </div>

    </div>

<h1 className="pt-24 text-4xl font-medium text-center uppercase lg:text-4xl">CINE</h1>    <Swiper
      className="mySwiper"
      modules={[Virtual, Pagination]}
      spaceBetween={10}
      slidesPerView={theme.state.width < 700 ? 1 : 4 }
      virtual={true}
      pagination={true}
      //onSlideChange={() => console.log('slide change')}
      //onSwiper={(swiper) => console.log(swiper)}
    >
      {projectsCine.length > 0 ? projectsCine.map((project,index) => (
      index < 4 ?

      <SwiperSlide key={'cine-'+ index} virtualIndex={index}>
        <div style={{}} className="flex flex-col items-center p-4 space-y-3 text-center">
        <NavLink className="glow rounded-xl overflow-hidden" to={'../cine/'+project.slug} state={{ from: 'home' }}>

        <picture style={{maxHeight: '300px',overflow:'hidden'}} id={'btn-' + index} className=".link">
        <ProgressiveImage
        src={project.image_thumb.url}
        placeholder={'/static/trans.gif'}
        modal={theme.modal}
        bg={'#333'}
        imgMargin={props.imgMargin}
        />
        </picture>

        <div className="flex flex-col items-center p-4 space-y-1 text-center bg-magenta  dark:bg-magenta">

            <p>{project.tax[0].name}</p>
            <h3 className="text-gl notranslate font-light text-white uppercase pb-6">{project.name}</h3>

        </div>

      </NavLink>
        </div>
    </SwiperSlide>

       : ''     ))  : ''}
     </Swiper>

     <div className="text-center p-4 mb-4 space-x-0 md:space-x-2 md:mb-8">
         <Link to="./cine" className="inline-flex items-center justify-center w-full px-6 py-3 mb-2 text-lg text-white bg-magenta rounded-xl sm:w-auto sm:mb-0">
             Ver m&aacute;s CINE
             <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
           </Link>
     </div>

     <h1 className="pt-24 text-4xl font-medium text-center uppercase lg:text-4xl">TV</h1>
     <Swiper
       className="mySwiper"
       modules={[Virtual, Pagination]}
       spaceBetween={10}
       slidesPerView={theme.state.width < 700 ? 1 : 4 }
       virtual={true}
       pagination={true}
     >
       {projectsTv.length > 0 ? projectsTv.map((project,index) => (
       index < 4 ?

       <SwiperSlide key={'tv-'+ index} virtualIndex={index}>
         <div className="h-full flex flex-col items-center p-4 space-y-3 text-center">
         <NavLink className="glow rounded-xl overflow-hidden" to={'../tv/'+project.slug} state={{ from: 'home' }}>

         <picture id={'btn-' + index} className=".link">
         <ProgressiveImage
         src={project.image_thumb.url}
         placeholder={'/static/trans.gif'}
         modal={theme.modal}
         bg={'#333'}
         imgMargin={props.imgMargin}
         />
         </picture>

         <div className="flex flex-col items-center p-4 space-y-1 text-center bg-magenta  dark:bg-magenta">

             <p>{project.tax[0].name}</p>
             <h3 className="text-gl notranslate font-light text-white uppercase pb-6">{project.name}</h3>

         </div>

       </NavLink>
         </div>
     </SwiperSlide>

        : ''     ))  : ''}
      </Swiper>

      <div className="text-center p-4 mb-4 space-x-0 md:space-x-2 md:mb-8">
          <Link to="./tv" className="inline-flex items-center justify-center w-full px-6 py-3 mb-2 text-lg text-white bg-magenta rounded-xl sm:w-auto sm:mb-0">
              Ver m&aacute;s TV
              <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </Link>
      </div>


      <h1 className="pt-24 text-4xl font-medium text-center uppercase lg:text-4xl">Documentales</h1>
      <Swiper
        className="mySwiper"
        modules={[Virtual, Pagination]}
        spaceBetween={10}
        slidesPerView={theme.state.width < 700 ? 1 : 4 }
        virtual={true}
        pagination={true}
      >
        {projectsDoc.length > 0 ? projectsDoc.map((project,index) => (
        index < 4 ?

        <SwiperSlide key={'doc-'+ index} virtualIndex={index}>
          <div className="h-full flex flex-col items-center p-4 space-y-3 text-center">
          <NavLink className="glow rounded-xl overflow-hidden" to={'../documentales/'+project.slug} state={{ from: 'home' }}>

          <picture id={'btn-' + index} className=".link">
          <ProgressiveImage
          src={project.image_thumb.url}
          placeholder={'/static/trans.gif'}
          modal={theme.modal}
          bg={'#333'}
          imgMargin={props.imgMargin}
          />
          </picture>

          <div className="flex flex-col items-center p-4 space-y-1 text-center bg-magenta  dark:bg-magenta">

              <p>{project.tax[0].name}</p>
              <h3 className="text-gl notranslate font-light text-white uppercase pb-6">{project.name}</h3>

          </div>

        </NavLink>
          </div>
      </SwiperSlide>

         : ''     ))  : ''}
       </Swiper>

       <div className="text-center p-4 mb-4 space-x-0 md:space-x-2 md:mb-8">
           <Link to="./documentales" className="inline-flex items-center justify-center w-full px-6 py-3 mb-2 text-lg text-white bg-magenta rounded-xl sm:w-auto sm:mb-0">
               Ver m&aacute;s DOCUMENTALES
               <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
             </Link>
       </div>

</section>
  )
}

export default Home
