export function themeReducer(state, action){
  switch(action.type) {
    case 'dark':
    return {pushOut:true, dark: true, width:state.width, height:state.height, vertical:state.vertical };

    case 'light':
    return { pushOut:false, dark: false, width:state.width, height:state.height, vertical:state.vertical };

    case 'viewport':
    return {pushOut:false, dark: state.dark, width: action.width, height:action.height, vertical:action.vertical};


    default:
    return state;

  }
}
