import React,{useState, useRef, useContext, useEffect} from 'react'
import { Outlet, NavLink, Link, useLoaderData } from "react-router-dom";
import { ThemeContext } from '../context/ThemeContext';
import { useDarkMode } from '../customhooks/ThemeHook';
import ProgressiveImage from './ProgressiveImage/FirstSlider';
import ImageRenderer from './ImageRenderer'
import gsap from 'gsap'
import './work_4.css'

function equalHeights(className) {
    var findClass = document.getElementsByClassName(className);
    var tallest = 0;
  // Loop over matching divs
  for(var i = 0; i < findClass.length; i++)
  {
    var ele = findClass[i];
    var eleHeight = ele.offsetHeight;
    tallest = (eleHeight>tallest ? eleHeight : tallest); /* look up ternary operator if you dont know what this is */
  }
  for(i = 0; i < findClass.length; i++)
  {
    findClass[i].style.height = tallest + "px";
  }
}


function News(props) {

  const theme = useContext(ThemeContext);
  const [darkMode, setDarkMode] = useDarkMode(false);
  const selectedWork = useRef();
  let NewsData = useLoaderData();
  console.log(NewsData)
  let News = NewsData.data;
  var source_url = '';


  function transitionEffect(e){
    gsap.to(selectedWork.current,1,{ delay:1, opacity:0,ease:'easeIn' });
  }

  useEffect((props) => {


    var debugInput = document.querySelector("input");
   function updateDebugState() {
       document.body.classList.toggle('debug-on', debugInput.checked);
   }
   debugInput.addEventListener("click", updateDebugState);
   updateDebugState();
});


useEffect((props) => {
  equalHeights('equalHeight');
});


  return (
    <>
      <div className="overflow-visible bg-cover bg-bottom block top-0 left-0 h-[100vh] flex" style={{marginTop:'-100px',width:'100%',height:'100vh',backgroundSize: theme.state.width < 1200 ? 'auto 100%' : '100% auto', backgroundPosition:'center',backgroundImage:'url(/img/bg-news-001.jpg)'}}>
        <div className="bg-gradient-black"></div>
    </div>

    <div style={{display:'none'}} className="debug">
    <label><input type="checkbox"/>Debug </label>
  </div>

  <div id="projects" style={{marginTop:'-45vh'}} className={"w-screen z-0 p-24 "  + props.transition}>
    <h1 style={{marginBottom:'20vh'}} className="pt-4 text-3xl font-bold text-center text-white capitalize lg:text-4xl ">{props.title}</h1>

  <div className="grid grid-cols-1 md:grid-cols-3 mx-auto">
  {News.length > 0 ? News.map((item,index) => {
    var blogDate = new Date(item.date).toLocaleDateString();

    return (
    <div key={'d_' + item.id} className="row">
    <div className="h-full flex flex-col items-center p-6 space-y-3 text-center">
      <NavLink className="glow rounded-xl overflow-hidden" to={'./'+item.slug}>

     <ImageRenderer
        styleclass="img-blog"
        url={source_url + item.image_thumb['url']}
        width={item.image_thumb['width'] }
        height={item.image_thumb['height']}
        alt_tag={'AF Films ' +item.title}
        />
        <div className="equalHeight flex flex-col items-center p-6 space-y-3 text-center bg-magenta  dark:bg-magenta">
    <span className="pt-0 mt-0 date-blog">{blogDate}</span>
    <h3 className="ttl-blog pb-2 mb-1">{item.title}</h3>
    <div className="excerpt-blog text-left mt-2" dangerouslySetInnerHTML={{ __html: item.excerpt}}/>
  </div>
  </NavLink>
  </div>
   </div>





               )}) : ''}
</div>
</div>

{/*   <div key={index} className="relative pl-9">
    <NavLink to={'./'+project.slug}>

      <h3> {project.name}</h3>
    </NavLink>
   </div>
   */}


  {/*<Link onClick={transitionEffect} ref={selectedWork} className="hidden absolute z-0" style={{width:'100%', height:'100%',overflow:'hidden',display:'flex',display: 'flex',justifyContent:'center'}}  to="./v-de-victor" relative="path">
  <div style={{backgroundImage: 'url("/img/3.jpg")'}} className={"is-active parallax__layer parallax__layer--base h-[130vh] w-screen z-0 bg-auto bg-no-repeat bg-center bg-cover bg-white" + props.transition}>
    <div className="title">
  </div>
 </div>
</Link>
*/}

  <div className="z-900">
  <Outlet/>
</div>

</>
  )
}

export default News
