import React,{useState, useRef, useEffect, useLayoutEffect, useContext} from 'react'
import { Link, useLoaderData, useParams,useLocation, useNavigate } from "react-router-dom"
import { ThemeContext } from '../context/ThemeContext';
import { useDarkMode } from '../customhooks/ThemeHook';
import {SplitText} from "@cyriacbr/react-split-text"
import TitleAnimated from './TitleAnimated'
import { BeakerIcon } from '@heroicons/react/24/solid'
import gsap from 'gsap'
//import { SplitText } from "gsap/SplitText"
import './work_4.css'



function NewsView(props) {

  function findItemPerKey(slug,data){
    // the code you're looking for
    var slug = slug;
    var obj = data;
    // iterate over each element in the array
    for (var i = 0; i < obj.length; i++){
      // look for the entry with a matching `code` value
      if (obj[i].slug == slug){
         // we found it
         return (obj[i]);
      }
    }
  }
const location = useLocation();
const navigate = useNavigate();
const theme = useContext(ThemeContext);
const { id } = useParams();
console.log(id);

  let dataItems = (useLoaderData());
  console.log(dataItems);


  var bg_image = null;
  var _coef = 10;
  var _coefDefault = 10;
  var _coefMobile = 30;
  var maxScale = 0.4
  var maxScaleDefault = 0.5;
  var maxScaleMobile = 1.2;
  const smoothSc = useRef();
  const growRef = useRef();
  const featuredImgRef = useRef();

  const [dataAll, setDataAll] = useState();
  const [scrollValue, setScrollValue] = useState(0);
  const [featuredImage, setFeaturedImage] = useState();
  const [darkMode, setDarkMode] = useDarkMode(false);

  const  [pushOut,setPushOut] = useState();

  function outOfView(){
  setPushOut(false);
  }
  //const [direction, setDirection] = useState(null);
  //const [scrollSpeed, setScrollSpeed] = useState(0);
  //const [growIsIntersecting, setGrowIsIntersecting] = useState(false);



  let options = {
    rootMargin: "0px 0px 0px 0px",
    threshold: 0,
  };
  let callback = (entries, observer) => {
    entries.forEach((entry) => {
      // Each entry describes an intersection change for one observed
      // target element:
      //   entry.boundingClientRect
      //   entry.intersectionRatio
      //   entry.intersectionRect
      //   entry.isIntersecting
      //   entry.rootBounds
      //   entry.target
      //   entry.time

      //console.log(entry.isIntersecting);
      var elem = document.querySelector('#pictureGrow') ;
      //elem.style.transform =  'scale('+ 1 +  +')';


        if(entry.isIntersecting){
          //console.log('ok');
          //gsap.to('#pictureGrow',1,{ scale: 3,ease:'expo'})
          //growRef.current.style.position = 'fixed';


        }else{
          //console.log('not ok');
          //growRef.current.style.position = 'inherit';
        }
    });
  };
let observer = new IntersectionObserver(callback, options);

useEffect((props) => {
gsap.to(featuredImgRef.current,.3,{ delay:0, height:'120vh',ease:'easeIn' });
});

useEffect((props)=> {
observer.observe(growRef.current);
},[growRef]);



useLayoutEffect(()=>{

  var _dataAll = findItemPerKey(id,dataItems.data);
  setDataAll(_dataAll);
  setFeaturedImage(_dataAll.image_thumb.url);
if(darkMode === true){
  setDarkMode(!darkMode);
}

  },[location]);

useLayoutEffect(()=>{

  _coef = theme.state.vertical === true ? _coefMobile : _coefDefault;
  maxScale = theme.state.vertical === true ? maxScaleMobile : maxScaleDefault;
  //bg_image = theme.state.vertical === true ? setFeaturedImage(projData.proj_img_mobile) : setFeaturedImage(projData.proj_img);
  bg_image = featuredImage;
  //console.log('maxscale', maxScale);

  var box = document.getElementById('pictureGrow');
  smoothSc.current.addEventListener( 'scroll', function(e) {

        // normalize scroll position as percentage
        //setScrollValue(e.currentTarget.scrollTop);
        var _scrollValue = e.currentTarget.scrollTop / e.currentTarget.scrollHeight;
        //console.log(_scrollValue);
        var _direction = e.currentTarget.scrollTop - scrollValue < 0 ? -1 : 1;
      //  setDirection(_direction);

        var _scale = gsap.utils.clamp(1, maxScale, _scrollValue * _coef ); // returns 100
        //var _scale = 1 + (e.currentTarget.scrollTop / e.currentTarget.scrollHeight); // returns 100


        //setScrollSpeed(_scale);

        var scrolled = _scale ,
        transformValue = 'scale('+scrolled+')';
        //gsap.to(box,.2,{delay:2, scale: _scale, ease:'expo' });


      window.requestAnimationFrame((e) =>{
    box.style.WebkitTransform = transformValue;
    box.style.MozTransform = transformValue;
    box.style.OTransform = transformValue;
    box.style.transform = transformValue;
      });



  }, false);

},[theme.state.vertical])







  return (


  <div style={{ background:'#000', width:'100vw',height:'100%',display:'block',overflowY:'scroll',position:'fixed',top:0,left:0}} id="project" className={"center pushOut pushOut-" + darkMode}>

  <div ref={smoothSc} id="project_parallax" className={"parallax bg-dark " + props.transition}>

    <div id="group3" className="parallax__group bg-black">
      <div className="parallax__layer parallax__layer--fore">
      <div className="title">

      <TitleAnimated
      level={1}
      id_t={'project_title'}
      classes={'proj-title text-left  pt-6  lg:pt-31 xl:pt-80 pb-4 text-title lg:text-title_lg xl:text-title_lg leading-6*2'}
      letterSpeed={0.02}
      scale={theme.state.width < 900 ? 1.2 : 1.5}
      color="white"
      title={dataAll ? dataAll.title :'...'}
      afterTitle={true}
      />


      <div className="afterTitle opacity-0">
    {/*  <h3 className="text-white text-left text-2xl font-light pb-10 pl-3">{dataAll ? dataAll.tax[0].name : ''}</h3> */}
      <div className="font-light grid grid-cols-4 gap-2 w-4/5 center text-sm pb-20">
        <button className="closeProject link"
          onClick={()=> {setDarkMode(!darkMode);
            if(location.state ){
            console.log(location.state.from);
            //navigate("/");
          }
          }}
           style={{zIndex:200,color: darkMode && 'white', background: darkMode && '#db7093'}}>

           {!darkMode ?
             <>
           <svg
             style={{transform:'scale(1.5)'}}
             fill="none"
             viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">

               <g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <rect width="24" height="24"></rect> <path d="M7 17L16.8995 7.10051" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M7 7.00001L16.8995 16.8995" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
               <span style={{marginTop:'-30px'}}>VOLVER</span>
           </>
           : ''}

         </button>
      {/*
        <div style={{whiteSpace: 'pre-line',verticalAlign: 'bottom'}} className="text-white text-left leading-5"><span className="font-medium">Client</span><br/>{projData.proj_header.client}</div>
        <div style={{whiteSpace: 'pre-line',verticalAlign: 'bottom'}} className="text-white text-left leading-5"><span className="font-medium">Services</span><br/>{projData.proj_header.services}</div>
        <div style={{whiteSpace: 'pre-line',verticalAlign: 'bottom'}} className="text-white text-left leading-5"><span className="font-medium">industries</span><br/>{projData.proj_header.industry}</div>
        <div style={{whiteSpace: 'pre-line',verticalAlign: 'bottom'}} className="text-white text-left leading-5"><span className="font-medium">Date</span><br/>{projData.proj_header.date}</div>
        */}
      </div>
    {/*  <p style={{whiteSpace: 'pre-line',verticalAlign: 'bottom'}} className="hidden md:block text-white text-left text-1xl lg:text-4l font-Regular w-4/5 xl:w-3/5">{projData.proj_excerpt}</p> */}

      </div>
      </div>
      </div>
      <div ref={featuredImgRef} style={{opacity:'.8',backgroundImage: 'url("' + featuredImage + '")'}} className="is-active parallax__layer parallax__layer--base  h-[100vh] w-screen z-0 bg-auto bg-no-repeat bg-center bg-contain">
        <div className="title">{/*Base Layer*/}</div>
      </div>
    </div>
    <div id="group4" className="parallax__group">

  {/*  <div className="parallax__layer parallax__layer--back">
      <div className="title">Background Layer</div>
    </div> */}
    <div className="parallax__layer parallax__layer--base bg-dark">
      <div className="title"></div>
    </div>

      <div style={{height:'auto'}} className="parallax__layer parallax__layer--fore">
        <div className="">

        <div className="container h-full mx-auto pb-24 w-[80%] md:w-[50%]">

        <TitleAnimated
        level={2}
        id_t={'project_headline'}
        classes={'p-4 proj-title pt-16 pb-6  text-title lg:text-title_lg xl:text-title_lg leading-8*2 opacity-3'}
        letterSpeed={0.02}
        scale={0.6}
        color="white"
        title={dataAll ? new Date(dataAll.date).toLocaleDateString() :'...'}
        afterTitle={true}/>

        <div style={{opacity:'.8'}} className="afterTitle">
        <div className="p-4 text-white" dangerouslySetInnerHTML={{__html: dataAll ? dataAll.description :'...' }}/>

      </div>

        </div>


    <div className="container">
    <div id="pictureGrow" className="galleryScale">
      <img ref={growRef} className="w-full" src={featuredImage} alt=""/>
    </div>
  </div>

        </div>
      </div>


    </div>



  </div>
</div>
  )
}

export default NewsView
