//https://github.com/vandit-bera/React-Routing
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import ErrorPage from './component/ErrorPage';
import Navbar from "./component/Navbar";
import App from './App';
import Legal from "./component/Legal";
import Blog from "./component/Blog";
import Contact from "./component/Contact";
import Home from "./component/Home";
import Services from "./component/Services";
import Team from "./component/Team";
//import WorkView from "./component/WorkView";
import WorkView_1 from "./component/WorkView_1";
import WorkDoc from "./component/WorkDoc";
import WorkCine from "./component/WorkCine";
import WorkTv from "./component/WorkTv";
import News from "./component/News";
import NewsView from "./component/NewsView";
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter,RouterProvider } from 'react-router-dom';
import ThemeProvider from './context/ThemeProvider';

const fetchProjectsHome = async () => {
const response = await fetch('https://discover.frankariza.com/app/v1/video-data-af-all');
return await response.json();
};
const fetchProjectsCine = async () => {
const response = await fetch('https://discover.frankariza.com/app/v1/video-data-af-cine');
return await response.json();
};
const fetchProjectsTv = async () => {
const response = await fetch('https://discover.frankariza.com/app/v1/video-data-af-tv');
return await response.json();
};
const fetchProjectsDoc = async () => {
const response = await fetch('https://discover.frankariza.com/app/v1/video-data-af-doc');
return await response.json();
};
const fetchNews = async () => {
const response = await fetch('https://discover.frankariza.com/api/v1/blog');
return await response.json();
};


const router = createBrowserRouter([
  {
    element: <App />,
     errorElement: <ErrorPage transition={`${'fadeIn'}`}/>,
    children: [
  {
    path: "/",
    element: <Home transition={`${'fadeIn'}`} />,
    loader: fetchProjectsHome,
  },
  {
    path: "/cine",
    element: <WorkCine title={'CINE'} transition={`${'fadeIn'}`} />,
    loader: fetchProjectsCine,
    children: [
      {
        path: ":id",
        element: <WorkView_1 transition={`${'fadeIn'}`} />,
        loader: fetchProjectsCine,
        errorElement: <ErrorPage transition={`${'fadeIn'}`} />,
      },
    ],
  },
  {
    path: "/documentales",
    element: <WorkDoc title={'documentales'} transition={`${'fadeIn'}`} />,
    loader: fetchProjectsDoc,
    children: [
      {
        path: ":id",
        element: <WorkView_1 transition={`${'fadeIn'}`} />,
        loader: fetchProjectsDoc,
        errorElement: <ErrorPage transition={`${'fadeIn'}`} />,
      },
    ],
  },
  {
    path: "/tv",
    element: <WorkTv title={'TV'} transition={`${'fadeIn'}`} />,
    loader: fetchProjectsTv,
    children: [
      {
        path: ":id",
        element: <WorkView_1 transition={`${'fadeIn'}`} />,
        loader: fetchProjectsTv,
        errorElement: <ErrorPage transition={`${'fadeIn'}`} />,
      },
    ],
  },
  {
    path: "/noticias",
    element: <News title={'NOTICIAS'} transition={`${'fadeIn'}`} />,
    loader: fetchNews,
    children: [
      {
        path: ":id",
        element: <NewsView transition={`${'fadeIn'}`} />,
        loader: fetchNews,
        errorElement: <ErrorPage transition={`${'fadeIn'}`} />,
      },
    ],
  },
  {
    path: "/servicios",
    element: <Services transition={`${'fadeIn'}`} />,
  },
  {
    path: "/contacto",
    element: <Contact transition={`${'fadeIn'}`} />,
  },
  {
    path: "/legal",
    element: <Legal transition={`${'fadeIn'}`} />,
  },
]
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <ThemeProvider>

    <RouterProvider router={router} future={{ v7_startTransition: true }}>
<Navbar />
    </RouterProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
