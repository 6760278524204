import React,{useState, useRef, useContext, useEffect} from 'react'
import { Outlet, NavLink, Link, useLoaderData } from "react-router-dom";
import { ThemeContext } from '../context/ThemeContext';
import { useDarkMode } from '../customhooks/ThemeHook';
import ProgressiveImage from './ProgressiveImage/FirstSlider';

import gsap from 'gsap'
import './work_4.css'


function equalHeights(className) {
    var findClass = document.getElementsByClassName(className);
    var tallest = 0;
  // Loop over matching divs
  for(var i = 0; i < findClass.length; i++)
  {
    var ele = findClass[i];
    var eleHeight = ele.offsetHeight;
    tallest = (eleHeight>tallest ? eleHeight : tallest); /* look up ternary operator if you dont know what this is */
  }
  for(i = 0; i < findClass.length; i++)
  {
    findClass[i].style.height = tallest + "px";
  }
}

function WorkCine(props) {

  const theme = useContext(ThemeContext);
  const [darkMode, setDarkMode] = useDarkMode(false);
  const selectedWork = useRef();
  let projectsData = useLoaderData();
  let projects = projectsData.items;


  function transitionEffect(e){
    gsap.to(selectedWork.current,1,{ delay:1, opacity:0,ease:'easeIn' });
  }

  useEffect((props) => {


    var debugInput = document.querySelector("input");
   function updateDebugState() {
       document.body.classList.toggle('debug-on', debugInput.checked);
   }
   debugInput.addEventListener("click", updateDebugState);
   updateDebugState();
});

useEffect((props) => {
  equalHeights('equalHeight');
});



  return (
    <>
      <div className="overflow-visible bg-cover bg-bottom block top-0 left-0 h-[100vh] flex" style={{marginTop:'-100px',width:'100%',height:'100vh',backgroundSize: projectsData.device ==='mobile' ? 'auto 100%' : '100% auto', backgroundPosition:'center',backgroundImage:'url(/img/bg-serie.webp)'}}>
        <div className="bg-gradient-black"></div>
    </div>

    <div style={{display:'none'}} className="debug">
    <label><input type="checkbox"/>Debug </label>
  </div>

  <div id="projects" style={{marginTop:'-45vh'}} className={"w-screen z-0 p-24 "  + props.transition}>
    <h1 style={{marginBottom:'20vh'}} className="pt-4 text-3xl font-bold text-center text-white capitalize lg:text-4xl ">{props.title}</h1>

  <div className="grid grid-cols-1 md:grid-cols-3 mx-auto">
  {projects.length > 0 ? projects.map((project,index) => (


      <div key={index} className="h-full flex flex-col items-center p-6 space-y-3 text-center">
      <NavLink className="glow rounded-xl overflow-hidden" to={'./'+project.slug}>

      <picture id={'btn-' + index} className=".link">
      <ProgressiveImage
      src={project.image_thumb.url}
      placeholder={'/static/trans.gif'}
      modal={theme.modal}
      bg={'#333'}
      imgMargin={props.imgMargin}
      />
      </picture>

      <div className="equalHeight flex flex-col items-center p-6 space-y-3 text-center bg-magenta  dark:bg-magenta">
          <span className="inline-block p-3 bg-magenta-dark text-white rounded-full dark:text-white dark:bg-magenta-dark">
            <svg fill="#fff" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
               width="30px" height="30px" viewBox="0 0 61.587 61.587">
                 <g>
                 	<g>
                 		<path d="M24.235,41.264L15.77,54.189h2.938l4.207-6.35l6.094,4.681l-6.284,9.067h3.318l7.223-10.38l2.664-0.033l5.249,6.876h3.318
                 			l-8.354-10.98L50.1,27.007h0.425v-1.85l-0.896-0.313v-10.48h1.25v-1.087l-12.023-2.748h-0.436V8.652l0.038-0.001V0L11.199,0.381
                 			v8.27v0.762v2.503v1.143v10.284h-0.49v1.25l1.285,0.713L24.235,41.264z M20.981,33.435l-3.203-4.926l1.473,0.815l3.605,4.723
                 			L20.981,33.435z M22.645,29.325h0.308V28.55c3.59,0.163,6.799,0.135,9.614-0.004l-4.864,7.426L22.645,29.325z M23.223,36.289
                 			l1.779,0.57l1.14,1.493l-0.585,0.893L23.223,36.289z M30.351,50.583l-6.087-4.776l1.601-2.417l4.975,6.486L30.351,50.583z
                 			 M29.752,44.557l-2.534-3.21l0.562-0.849l3.195,4.186L29.752,44.557z M34.386,44.761l-5.04-6.627l4.252-6.415l7.106,3.922
                 			L34.386,44.761z M46.69,27.007l-4.627,6.677l-7.335-3.667l1.123-1.695c5.09-0.448,8.496-1.228,10.116-1.667l0.858,0.352
                 			L46.69,27.007L46.69,27.007z M38.419,12.126l9.305,2.225v9.831L39.466,21.3h-1.047V12.126z M13.104,9.359l23.413-0.655v1.823
                 			v1.143v9.63h-0.586v1.2l-0.246-0.085c-5.278,0.742-10.628,1.095-14.527,1.262v-7.682h1.251v-1.087l-8.869-2.992h-0.435
                 			L13.104,9.359L13.104,9.359z M13.104,13.748l6.147,2.228v7.775c-2.447,0.08-3.971,0.081-3.972,0.081l-1.034-0.49h-1.142V13.748z"
                 			/>
                 	</g>
                 </g>
            </svg>
          </span>
          <p>{project.tax[0].name}</p>
          <h3 className="text-2xl font-light text-white uppercase">{project.name}</h3>

          <p className="text-base text-gray-100" dangerouslySetInnerHTML={{__html: project.excerpt }}/>



    {/*     <a href="#" className="flex items-center -mx-1 text-md text-white capitalize transition-colors duration-300 transform dark:magenta-light hover:underline hover:text-white dark:hover:text-white"> */}
              <div className="flex items-center -mx-1 text-md text-white capitalize transition-colors duration-300 transform dark:magenta-light hover:underline hover:text-white dark:hover:text-white">
              <span className="mx-1">Descubrir</span>
              <svg className="w-4 h-4 mx-1 rtl:-scale-x-100" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </div>
        {/*  </a> */}
      </div>

    </NavLink>
      </div>


               )) : ''}
</div>
</div>

{/*   <div key={index} className="relative pl-9">
    <NavLink to={'./'+project.slug}>

      <h3> {project.name}</h3>
    </NavLink>
   </div>
   */}


  {/*<Link onClick={transitionEffect} ref={selectedWork} className="hidden absolute z-0" style={{width:'100%', height:'100%',overflow:'hidden',display:'flex',display: 'flex',justifyContent:'center'}}  to="./v-de-victor" relative="path">
  <div style={{backgroundImage: 'url("/img/3.jpg")'}} className={"is-active parallax__layer parallax__layer--base h-[130vh] w-screen z-0 bg-auto bg-no-repeat bg-center bg-cover bg-white" + props.transition}>
    <div className="title">
  </div>
 </div>
</Link>
*/}

  <div className="z-900">
  <Outlet/>
</div>

</>
  )
}

export default WorkCine
